<template>
    <div class="forms">
        <div class="container mt-5 mb-5">
            <div v-if="isLoading" class="loading">
                <div class="container">
                    <div class="row">
                        <div class="d-flex justify-content-center">
                            <div class="spinner"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="card">
                <div v-if="mensagem_alerta" class="mt-3 mb-3 text-center alert" :class="mensagem_alerta.status">
                    <i :class="mensagem_alerta.icone"></i> {{ mensagem_alerta.mensagem }}
                </div>
                <div class="card-header">
                    <h5>Editar perfil</h5>
                </div>
                <div class="card-body">
                    <template v-if="etapa === 1">
                        <form @submit.prevent="validarAlteracoes">
                            <div class="row">
                                <div class="mb-3 col-md-6">
                                    <label for="nome" class="form-label">Nome</label>
                                    <input v-model="user.nome" placeholder="Nome" type="text" class="form-control">
                                </div>
                                <div class="mb-3 col-md-6">
                                    <label for="sobrenome" class="form-label">Sobrenome</label>
                                    <input v-model="user.sobrenome" placeholder="Sobrenome" type="text"
                                        class="form-control">
                                </div>
                            </div>

                            <div class="mb-3">
                                <label for="email" class="form-label">Email</label>
                                <input v-model="user.email" placeholder="Email" type="text" class="form-control">
                            </div>

                            <div class="mb-2">
                                <label for="usuario" class="form-label">Usuário</label>
                                <input @input="validarUsuario()" v-model="user.usuario" placeholder="Usuário"
                                    type="text" class="form-control">
                            </div>
                            <span :style="{ color: corMensagemUsuario }">{{ mensagemUsuario }}</span>

                            <div class="mb-3">
                                <h6 for="imagem" class="mt-3">Escolha uma imagem de perfil (tamanho máximo: 10MB)
                                </h6>

                                <div class="d-flex align-self-center justify-content-center">
                                    <div class="painel-usuario mb-5 mt-5">
                                        <img :src="user.foto_perfil ? `data:image/jpeg;base64,${user.foto_perfil}` : require('@/assets/imgs/profile.png')"
                                            class="img-fluid">
                                    </div>
                                </div>

                                <div class="input-group mb-3">
                                    <input type="file" class="form-control" id="inputGroupFile02"
                                        @change="handleFileChange">
                                </div>
                            </div>

                            <div class="mt-5 d-grid gap-2 col-6 mx-auto mb-5">
                                <button type="submit" class="btn btn-linknest">Salvar alterações</button>
                            </div>

                        </form>
                    </template>
                    <template v-else-if="etapa === 2">
                        <div class="text-center d-flex justify-content-center">
                            <h2 class="texto-successo mb-5">Sucesso!</h2>
                        </div>
                        <h5 class="text-center">Alterações feitas com sucesso, faça logout e depois faça login novamente
                            para que as alterações funcionem corretamente!</h5>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import axios from 'axios'
import { User } from '@/interfaces/User'
import { Alerta } from '@/interfaces/Alerta'

@Options({})
export default class EditarPerfil extends Vue {

    user: User = {
        id: '',
        nome: '',
        sobrenome: '',
        email: '',
        usuario: '',
        foto_perfil: null
    }

    etapa = 1
    isLoading = true
    mensagem_alerta: Alerta | null = null
    mensagemUsuario = ''
    corMensagemUsuario = 'black'

    mounted() {
        this.fetchUserData()
    }

    async fetchUserData() {
        const userId = localStorage.getItem('userId')
        if (userId) {
            try {
                //const response = await axios.get(`http://localhost/Projetos/linknest/src/backend/getPerfil.php?userId=${userId}`)
                const response = await axios.get(`https://linknest.com.br/src/backend/getPerfil.php?userId=${userId}`)
                if (response.data.status === 'success') {

                    this.user = {
                        ...this.user,
                        ...response.data.user,
                        id: userId
                    }

                } else {
                    console.error('Erro ao buscar dados do usuário:', response.data.message)
                }
            } catch (error) {
                console.error('Erro ao buscar dados do usuário:', error)
            }
        } else {
            console.error('Usuário não autenticado')
        }

        this.isLoading = false
    }

    public validarAlteracoes() { //validar alteracoes
        if (
            this.user.nome === '' ||
            this.user.sobrenome === '' ||
            this.user.email === '' ||
            this.user.usuario === ''
        ) {
            this.mostrarMensagemAlerta('fa-solid fa-circle-info', 'Você não pode deixar espaços em branco!', 'alert-danger')
        } else if (!this.validarEmail(this.user.email)) {
            this.mostrarMensagemAlerta('fa-solid fa-circle-exclamation', 'Digite um email válido!', 'alert-warning')
        } else if ((this.user.usuario as string).length < 5) { // Assertando o tipo para string
            this.mostrarMensagemAlerta('fa-solid fa-circle-exclamation', 'Digite um nome de usuário válido!', 'alert-warning')
        } else if (this.user.foto_perfil && !this.validarImagem(new File([""], this.user.foto_perfil))) {
            this.mostrarMensagemAlerta('fa-solid fa-circle-exclamation', 'Escolha uma imagem válida (PNG, JPG, JPEG, GIF) com tamanho máximo de 10MB!', 'alert-warning')
        } else {
            this.salvarAlteracoes()
        }
    }


    async salvarAlteracoes() { //salvar alteracoes
        try {
            const userId = localStorage.getItem('userId')
            if (!userId) {
                console.error('ID do usuário não encontrado no localStorage')
                return
            }

            this.isLoading = true // Define isLoading como true ao iniciar a requisição

            /*const response = await axios.post('http://localhost/Projetos/linknest/src/backend/editarPerfil.php', {
                id: userId,
                nome: this.user.nome,
                sobrenome: this.user.sobrenome,
                email: this.user.email,
                usuario: this.user.usuario,
                foto_perfil: this.user.foto_perfil
            })*/

            const response = await axios.post('https://linknest.com.br/src/backend/editarPerfil.php', {
                id: userId,
                nome: this.user.nome,
                sobrenome: this.user.sobrenome,
                email: this.user.email,
                usuario: this.user.usuario,
                foto_perfil: this.user.foto_perfil
            })

            if (response.data.status === 'success') {
                this.etapa = 2
                setTimeout(() => {
                    this.$router.push('/perfil')
                }, 5000)
            } else {
                this.$router.push('/perfil')
            }
        } catch (error) {
            console.error('Erro ao atualizar perfil:', error)
        } finally {
            this.isLoading = false
        }
    }

    handleFileChange(event: any) {
        const file = event.target.files[0];
        if (file) {
            this.validarImagem(file).then(isValid => {
                if (isValid) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        if (reader.result) {
                            this.user.foto_perfil = reader.result.toString().split(',')[1];
                        }
                    };
                    reader.readAsDataURL(file);
                } else {
                    this.mostrarMensagemAlerta('fa-solid fa-circle-exclamation', 'Imagem inválida. Apenas imagens de até 8000x8000 pixels e 10MB são permitidas.', 'alert-warning');
                }
            }).catch(() => {
                this.mostrarMensagemAlerta('fa-solid fa-circle-exclamation', 'Erro ao validar a imagem.', 'alert-danger');
            });
        } else {
            this.mostrarMensagemAlerta('fa-solid fa-circle-exclamation', 'Nenhuma imagem selecionada.', 'alert-warning');
        }
    }

    private validarImagem(file: File): Promise<boolean> { // validar imagem
        const maxSize = 10 * 1024 * 1024; // Tamanho máximo de 10MB
        const allowedMimeTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];

        // Verifica se o tipo MIME é permitido e se o tamanho está dentro do limite
        if (!allowedMimeTypes.includes(file.type) || file.size > maxSize) {
            return Promise.resolve(false);
        }

        // Verifica a resolução da imagem
        return new Promise<boolean>((resolve) => {
            const img = new Image();
            img.onload = () => {
                const isValidResolution = img.width <= 8000 && img.height <= 8000;
                resolve(isValidResolution);
            };
            img.onerror = () => {
                resolve(false);
            };
            img.src = URL.createObjectURL(file);
        });
    }

    private validarEmail(email: string): boolean { //validar email
        // Expressão regular para validar email
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return regex.test(email)
    }

    public validarUsuario() { //validar usuario

        const usuario = this.user.usuario
        if (usuario.length < 5) {
            this.mensagemUsuario = 'Usuário muito curto'
            this.corMensagemUsuario = 'red'
        } else if (usuario.length >= 5) {
            this.mensagemUsuario = 'Usuário válido'
            this.corMensagemUsuario = 'greenyellow'
        }

    }

    private mostrarMensagemAlerta(icone: string, mensagem: string, status: string) { //mostrar alerta
        this.mensagem_alerta = { icone, mensagem, status }
        setTimeout(() => {
            this.mensagem_alerta = null
        }, 5000) // Remover a mensagem após 5 segundos
    }
}
</script>

<style lang="scss">
@import '../scss/forms';
</style>
