<template>
    <div class="forms">
        <div class="container mt-5 mb-5">
            <div class="card">
                <div class="card-header">
                    <h3 class="bem-vindo">Bem vindo de volta</h3>
                    <div v-if="mensagemAlerta" :class="['text-center alert', mensagemAlerta.status]" role="alert">
                        <i :class="mensagemAlerta.icone"></i> {{ mensagemAlerta.mensagem }}
                    </div>
                    <h5>Faça login com sua conta LinkNest</h5>
                </div>
                <div class="card-body">
                    <form @submit.prevent="fazerLogin">
                        <div class="mb-3">
                            <label for="nome" class="form-label">Nome de usuário</label>
                            <input v-model="usuario" placeholder="Usuário" type="text" class="form-control">
                        </div>

                        <label for="senha" class="form-label">Senha</label>
                        <div class="input-group mb-3">
                            <input v-model="senha" :type="mostrar_senha ? 'text' : 'password'"
                                class="form-control white-text" placeholder="Senha" name="senha" aria-label="Senha"
                                aria-describedby="button-addon2">
                            <button @click="alternarExibicaoSenha()" class="btn btn-warning" type="button" id="senha">
                                <i class="fa-solid"
                                    :class="{ 'fa-eye-slash': mostrar_senha, 'fa-eye': !mostrar_senha }"></i>
                            </button>
                        </div>
                        <div class="mt-5 d-grid gap-2 col-6 mx-auto mb-5">
                            <button type="submit" class="btn btn-linknest">Fazer Login</button>
                        </div>

                        <!--<h6>Esqueceu sua senha? <router-link class="text-warning" to="esqueceu-sua-senha">Clique
                                aqui</router-link></h6>-->

                        <h6>Ainda não possui uma conta? <router-link class="text-warning" to="cadastro">Faça
                                seu cadastro</router-link> agora mesmo!</h6>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import axios from 'axios'
import { Alerta } from '@/interfaces/Alerta'
import { mapActions } from 'vuex'

@Options({
    methods: {
        ...mapActions(['login']) //ações de login com vuex
    }
})

export default class Login extends Vue {
    usuario = ''
    senha = ''
    mostrar_senha = false
    mensagemAlerta: Alerta | null = null

    // Declarar o método login
    login!: (user: any) => void

    public alternarExibicaoSenha() { //alternar exibicao senha
        this.mostrar_senha = !this.mostrar_senha
    }

    public async fazerLogin() { // Fazer login
        try {
            /*const response = await axios.post('http://localhost/Projetos/linknest/src/backend/login.php', {
                usuario: this.usuario,
                senha: this.senha
            })*/

            const response = await axios.post('https://linknest.com.br/src/backend/login.php', {
                usuario: this.usuario,
                senha: this.senha
            })

            if (response.data.status === 'success') {
                const userId = response.data.userId

                // Armazena o userId no localStorage
                localStorage.setItem('userId', userId)

                this.login(response.data.user)  // Despachar ação de login com dados do usuário
                this.mostrarMensagemAlerta('fa-solid fa-check', response.data.message, 'alert-success')
                this.$store.dispatch('login', { usuario: this.usuario })
                this.$router.push('/adicionar-links')
            } else {
                this.mostrarMensagemAlerta('fa-solid fa-times', response.data.message, 'alert-danger')
            }
        } catch (error) {
            console.error('Erro ao fazer login:', error)
            this.mostrarMensagemAlerta('fa-solid fa-times', 'Erro ao fazer login', 'alert-danger')
        }
    }

    private mostrarMensagemAlerta(icone: string, mensagem: string, status: string): void { //mensagem de
        this.mensagemAlerta = { icone, mensagem, status }
        setTimeout(() => {
            this.mensagemAlerta = null
        }, 5000)
    }
}
</script>

<style lang="scss">
@import '../scss/forms';
</style>