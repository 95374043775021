<template>
    <div class="forms">
        <div class="container mt-5 mb-5">
            <div class="card">
                <div class="card-header">
                    <h3 class="bem-vindo">Bem vindo (a), {{ user?.usuario }}</h3>
                    <h5>Seu URL linknest</h5>
                </div>
                <div class="card-body">
                    <form>
                        <div class="mb-3">
                            <input :value="'https://linknest.com.br/' + user?.usuario" type="text" disabled
                                class="form-control">
                        </div>
                        <div class="d-flex justify-content-end">
                            <router-link to="/perfil" type="submit" class="btn btn-linknest">Alterar</router-link>
                        </div>
                    </form>
                </div>
            </div>
            <div class="card mt-5">
                <div class="card-header">
                    <h5>Adicione seus links</h5>
                </div>
                <div v-if="mensagem_alerta" class="mt-3 mb-3 text-center alert" :class="mensagem_alerta.status">
                    <i :class="mensagem_alerta.icone"></i> {{ mensagem_alerta.mensagem }}
                </div>
                <div class="card-body">
                    <form @submit.prevent="salvarConfiguracoesLink">
                        <div v-if="adicionar_link">
                            <div class="mb-3 mt-3">
                                <input type="text" class="form-control" v-model="novoLink.nome_link"
                                    placeholder="Nome do link">
                            </div>
                            <div class="mb-3 input-group">
                                <div class="input-group-append">
                                    <h5 class="mt-1">URL</h5>
                                </div>
                                <input type="text" class="form-control" v-model="novoLink.url_link">
                            </div>
                            <div class="d-flex justify-content-end">
                                <button type="submit" class="btn btn-linknest">Salvar link</button>
                            </div>
                        </div>
                        <div class="mt-5 d-flex justify-content-center">
                            <button @click.prevent="adicionarLink()" type="submit" class="btn btn-linknest">
                                <i class="fa-solid fa-plus"></i> Adicionar link
                            </button>
                        </div>

                        <div v-if="isLoading" class="loading-links">
                            <div class="container">
                                <div class="row">
                                    <div class="d-flex justify-content-center">
                                        <div class="spinner"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-else>
                            <div v-for="link in links" :key="link.id" class="card-link mt-5 m-3">
                                <div class="row">
                                    <div class="col">
                                        <div class="card-body">
                                            <!-- Nome do link -->
                                            <div class="mb-2">
                                                <h6 v-if="!link.editingNome" class="mb-0">{{ link.nome_link }}</h6>
                                                <input v-if="link.editingNome" v-model="link.nome_link"
                                                    class="form-control" type="text">
                                            </div>

                                            <!-- URL do link -->
                                            <div class="mb-2">
                                                <p v-if="!link.editingURL" class="mb-0">{{ link.url_link }}</p>
                                                <input v-if="link.editingURL" v-model="link.url_link"
                                                    class="form-control" type="text">
                                            </div>

                                            <!-- Botões -->
                                            <div class="d-md-flex justify-content-between mt-3">
                                                <!-- Botões de Edição e Remoção -->
                                                <div>
                                                    <button v-if="!link.editingNome"
                                                        @click.prevent="editarNomeLink(link)"
                                                        class="d-grid gap-2 d-md-block col-6 mx-auto mb-5 btn btn-links">
                                                        <i class="fa-solid fa-pen-to-square"></i> Editar Nome
                                                    </button>
                                                    <button v-if="link.editingNome"
                                                        @click.prevent="salvarNomeLink(link)"
                                                        class="d-grid gap-2 d-md-block col-6 mx-auto mb-5 btn btn-links">
                                                        <i class="fa-solid fa-check"></i> Salvar
                                                    </button>
                                                </div>
                                                <div>
                                                    <button v-if="!link.editingURL" @click.prevent="editarLink(link)"
                                                        class="d-grid gap-2 d-md-block col-6 mx-auto mb-5 btn btn-links">
                                                        <i class="fa-solid fa-pen-to-square"></i> Editar URL
                                                    </button>
                                                    <button v-if="link.editingURL" @click.prevent="salvarURL(link)"
                                                        class="d-grid gap-2 d-md-block col-6 mx-auto mb-5 btn btn-links">
                                                        <i class="fa-solid fa-check"></i> Salvar
                                                    </button>
                                                </div>
                                                <div>
                                                    <button @click.prevent="removerLink(link.id)"
                                                        class="d-grid gap-2 d-md-block col-6 mx-auto mb-5 btn btn-links">
                                                        <i class="fa-solid fa-trash"></i> Remover
                                                    </button>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="mt-5 d-grid gap-2 col-6 mx-auto mb-5">
                                <button @click="visualizarLinkNest()" type="submit"
                                    class="btn btn-linknest">Visualizar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import axios from 'axios'
import { Link } from '@/interfaces/Link'
import { Alerta } from '@/interfaces/Alerta'

@Options({})
export default class AdicionarLinks extends Vue {

    adicionar_link = false
    mensagem_alerta: Alerta | null = null
    isLoading = true

    novoLink: Link = {
        id: 0,
        nome_link: '',
        url_link: ''
    }

    links: Link[] = []

    mounted() { //chamar funcao de exibir links
        this.buscarLinks()
    }

    get isAuthenticated() { //usuario autenticado
        return this.$store.getters.isAuthenticated
    }

    get user() { //exibir usuario autenticado
        return this.$store.getters.user
    }

    public adicionarLink() { //adicionar link
        this.adicionar_link = true
    }

    public async buscarLinks() { //buscar links inseridos pelo usuario
        try {
            const userId = localStorage.getItem('userId')
            if (userId) {
                //const response = await axios.get(`http://localhost/Projetos/linknest/src/backend/buscarLinks.php?user_id=${userId}`)
                const response = await axios.get(`https://linknest.com.br/src/backend/buscarLinks.php?user_id=${userId}`)
                this.links = response.data.map((link: Link) => ({
                    ...link,
                    editingNome: false,
                    editingURL: false
                }))
            }
        } catch (error) {
            console.error('Erro ao buscar links:', error)
        }

        this.isLoading = false
    }

    public async salvarConfiguracoesLink() { //salvar configuracoes do link
        try {
            this.isLoading = true

            const userId = localStorage.getItem('userId')
            if (userId) {
                const formData = new FormData()
                formData.append('user_id', userId)
                formData.append('nome_link', this.novoLink.nome_link)
                formData.append('url_link', this.novoLink.url_link)

                //const response = await axios.post('http://localhost/Projetos/linknest/src/backend/adicionarLink.php', formData)
                const response = await axios.post('https://linknest.com.br/src/backend/adicionarLink.php', formData)

                if (response.data.status === 'success') {
                    this.adicionar_link = false
                    this.novoLink.nome_link = ''
                    this.novoLink.url_link = ''
                    this.buscarLinks()
                    this.mostrarMensagemAlerta('fa-solid fa-check', response.data.message, 'alert-success')
                } else if (response.data.status === 'primary') {
                    this.mostrarMensagemAlerta('fa-solid fa-circle-info', response.data.message, 'alert-primary')
                } else {
                    console.error('Erro ao adicionar link:', response.data.message)
                }
            }

            this.isLoading = false

        } catch (error) {
            console.error('Erro ao adicionar link:', error);
        }
    }

    public editarNomeLink(link: Link) {
        link.editingNome = true
    }

    public editarLink(link: Link) {
        link.editingURL = true
    }

    public async salvarNomeLink(link: Link) { //salvar nome do link
        if (link.nome_link !== link.original_nome_link) {
            try {
                //const response = await axios.post('http://localhost/Projetos/linknest/src/backend/editarNomeLink.php', { link_id: link.id, nome_link: link.nome_link });
                const response = await axios.post('https://linknest.com.br/src/backend/editarNomeLink.php', { link_id: link.id, nome_link: link.nome_link });
                if (response.data.status === 'success') {
                    this.mostrarMensagemAlerta('fa-solid fa-check', response.data.message, 'alert-success')
                    link.editingNome = false
                } else if (response.data.status === 'secondary') {
                    this.mostrarMensagemAlerta('fa-solid fa-circle-check', response.data.message, 'alert-secondary')
                    link.editingNome = false
                } else {
                    this.mostrarMensagemAlerta('fa-solid fa-exclamation-triangle', response.data.message, 'alert-danger')
                }
            } catch (error) {
                console.error('Erro ao salvar o nome do link:', error)
            }
        } else {
            link.editingNome = false
        }
    }

    public async salvarURL(link: Link) { //salvar url
        if (link.url_link !== link.original_url_link) {
            try {
                //const response = await axios.post('http://localhost/Projetos/linknest/src/backend/editarURLLink.php', { link_id: link.id, url_link: link.url_link });
                const response = await axios.post('https://linknest.com.br/src/backend/editarURLLink.php', { link_id: link.id, url_link: link.url_link });
                if (response.data.status === 'success') {
                    this.mostrarMensagemAlerta('fa-solid fa-check', response.data.message, 'alert-success')
                    link.editingURL = false
                } else if (response.data.status === 'secondary') {
                    this.mostrarMensagemAlerta('fa-solid fa-circle-check', response.data.message, 'alert-secondary')
                    link.editingURL = false
                } else {
                    this.mostrarMensagemAlerta('fa-solid fa-exclamation-triangle', response.data.message, 'alert-danger')
                }
            } catch (error) {
                console.error('Erro ao salvar a URL do link:', error)
            }
        } else {
            link.editingURL = false
        }
    }


    public async removerLink(id: number) { //remover link
        try { // Debug: Verifica o ID do link
            this.isLoading = true
            //const response = await axios.post('http://localhost/Projetos/linknest/src/backend/removerLink.php', { link_id: id })
            const response = await axios.post('https://linknest.com.br/src/backend/removerLink.php', { link_id: id })

            if (response.data.status === 'success') {
                this.links = this.links.filter(link => link.id !== id)
                this.mostrarMensagemAlerta('fa-solid fa-check', response.data.message, 'alert-success')
            } else {
                console.error('Erro ao remover link:', response.data.message)
            }
            this.isLoading = false
        } catch (error) {
            console.error('Erro ao remover link:', error)
        }
    }

    public visualizarLinkNest() {
        if (this.user?.usuario) {
            this.$router.push({ name: 'link-nest-usuario', params: { usuario: this.user.usuario } })
        }
    }




    private mostrarMensagemAlerta(icone: string, mensagem: string, status: string) { //mostrar alerta
        this.mensagem_alerta = { icone, mensagem, status }
        setTimeout(() => {
            this.mensagem_alerta = null
        }, 5000) // Remover a mensagem após 5 segundos
    }
}
</script>

<style lang="scss">
@import '../scss/forms.scss';
</style>