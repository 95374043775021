<template>
    <div class="forms">
        <div class="container mt-5 mb-5">
            <!-- Etapa 1 -->
            <div class="card" v-if="etapa === 1">
                <div class="card-header">
                    <h3 class="bem-vindo">Seja bem vindo ao LinkNest</h3>
                    <h5>Faça seu cadastro agora mesmo!</h5>
                    <div v-if="mensagem_alerta" class="mt-3 mb-3 text-center alert" :class="mensagem_alerta.status">
                        <i :class="mensagem_alerta.icone"></i> {{ mensagem_alerta.mensagem }}
                    </div>
                </div>
                <div class="card-body">
                    <form @submit.prevent="validarFormulario">
                        <div class="row">
                            <div class="mb-3 col-md-6">
                                <label for="nome" class="form-label">Nome</label>
                                <input v-model="formData.nome" placeholder="Usuário" type="text" class="form-control">
                            </div>
                            <div class="mb-3 col-md-6">
                                <label for="sobrenome" class="form-label">Sobrenome</label>
                                <input v-model="formData.sobrenome" placeholder="Sobrenome" type="text"
                                    class="form-control">
                            </div>
                        </div>

                        <div class="mb-3">
                            <label for="email" class="form-label">Email</label>
                            <input v-model="formData.email" placeholder="Digite seu e-mail" type="text"
                                class="form-control">
                        </div>

                        <div class="mb-3">
                            <label for="basic-url" class="form-label">Usuário</label>
                            <div class="input-group">
                                <span class="d-none d-lg-inline input-group-text"
                                    id="basic-addon3">https://linknest.com.br/</span>
                                <input v-model="formData.usuario" @input="validarUsuario" type="text"
                                    class="form-control" aria-describedby="basic-addon3 basic-addon4">
                            </div>
                            <span :style="{ color: corMensagemUsuario }">{{ mensagemUsuario }}</span>
                        </div>

                        <label for="senha" class="form-label">Senha</label>
                        <div class="input-group">
                            <input v-model="formData.senha" @input="validarSenha"
                                :type="mostrar_senha ? 'text' : 'password'" class="form-control white-text"
                                placeholder="Senha" name="senha" aria-label="Senha" aria-describedby="button-addon2">

                            <button @click="alternarExibicaoSenha" class="btn btn-warning" type="button" id="senha">
                                <i class="fa-solid"
                                    :class="{ 'fa-eye-slash': mostrar_senha, 'fa-eye': !mostrar_senha }"></i>
                            </button>
                        </div>
                        <span :style="{ color: corMensagemSenha }">{{ mensagemSenha }}</span>

                        <div class="mt-5 d-grid gap-2 col-6 mx-auto mb-5">
                            <button type="submit" class="btn btn-linknest">Fazer cadastro</button>
                        </div>

                        <h6>Já possui uma conta? <router-link class="text-warning" to="login">Faça
                                login</router-link> agora mesmo!</h6>

                    </form>
                </div>
            </div>

            <!-- Etapa 2 -->
            <div class="card" v-if="etapa === 2">
                <div class="card-header">
                    <h3 class="bem-vindo">Seja bem vindo ao LinkNest</h3>
                    <h5>Faça seu cadastro agora mesmo!</h5>
                    <div v-if="mensagem_alerta" class="mt-3 mb-3 text-center alert" :class="mensagem_alerta.status">
                        <i :class="mensagem_alerta.icone"></i> {{ mensagem_alerta.mensagem }}
                    </div>
                </div>
                <div class="card-body">
                    <form @submit.prevent="validarFormulario">
                        <div class="mb-3">
                            <h6 for="imagem" class="">Escolha uma imagem de perfil (tamanho max: 10MB)</h6>

                            <div class="d-flex align-self-center justify-content-center">
                                <div class="imagem-perfil mb-5 mt-5">
                                    <img v-if="imagemPreview" :src="imagemPreview" class="img-preview">
                                </div>
                            </div>


                            <div class="input-group mb-3">
                                <input type="file" class="form-control" @change="onFileChange" required>
                            </div>
                        </div>

                        <div class="d-flex justify-content-between">
                            <div class="mt-5 d-grid gap-2 col-5 mx-auto mb-5">
                                <button @click="etapaAnterior" type="button" class="btn btn-linknest">Etapa
                                    anterior</button>
                            </div>
                            <div class="mt-5 d-grid gap-2 col-5 mx-auto mb-5">
                                <button type="submit" class="btn btn-linknest">Fazer cadastro</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import axios from 'axios'
import { Alerta } from '@/interfaces/Alerta'

@Options({})
export default class Cadastro extends Vue {
    mostrar_senha = false
    etapa = 1
    imagemPreview: string | null = null
    mensagem_alerta: Alerta | null = null
    mensagemSenha = ''
    corMensagemSenha = 'black'
    mensagemUsuario = ''
    corMensagemUsuario = 'black'

    formData = {
        nome: '',
        sobrenome: '',
        email: '',
        usuario: '',
        senha: '',
        foto_perfil: null as File | null
    }

    public alternarExibicaoSenha() { //alternar exibicao senha
        this.mostrar_senha = !this.mostrar_senha
    }

    public onFileChange(event: Event) {
        const fileInput = event.target as HTMLInputElement
        if (fileInput.files && fileInput.files[0]) {
            const file = fileInput.files[0];
            this.validarImagem(file).then(isValid => {
                if (isValid) {
                    this.formData.foto_perfil = file;
                    this.imagemPreview = URL.createObjectURL(this.formData.foto_perfil);
                } else {
                    this.mostrarMensagemAlerta('fa-solid fa-circle-exclamation', 'Imagem inválida. Apenas imagens de até 8000x8000 pixels e 10MB são permitidas.', 'alert-warning');
                }
            }).catch(() => {
                this.mostrarMensagemAlerta('fa-solid fa-circle-exclamation', 'Erro ao validar a imagem.', 'alert-danger');
            });
        } else {
            this.mostrarMensagemAlerta('fa-solid fa-circle-exclamation', 'Nenhuma imagem selecionada.', 'alert-warning');
        }
    }

    private validarImagem(file: File): Promise<boolean> {
        const maxSize = 10 * 1024 * 1024; // Tamanho máximo de 10MB
        const allowedMimeTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];

        // Verifica se o tipo MIME é permitido e se o tamanho está dentro do limite
        if (!allowedMimeTypes.includes(file.type) || file.size > maxSize) {
            return Promise.resolve(false);
        }

        // Verifica a resolução da imagem
        return new Promise<boolean>((resolve) => {
            const img = new Image();
            img.onload = () => {
                const isValidResolution = img.width <= 8000 && img.height <= 8000;
                resolve(isValidResolution);
            };
            img.onerror = () => {
                resolve(false);
            };
            img.src = URL.createObjectURL(file);
        });
    }

    public proximaEtapa() { //proxima etapa
        this.etapa++
    }

    public etapaAnterior() { //etapa anterior
        this.etapa--
    }

    public validarFormulario() { //validar formulario
        if (this.etapa === 1) {
            // Validação para a primeira etapa (campos básicos)
            if (
                this.formData.nome === '' ||
                this.formData.sobrenome === '' ||
                this.formData.email === '' ||
                this.formData.usuario === '' ||
                this.formData.senha === ''
            ) {
                this.mostrarMensagemAlerta('fa-solid fa-circle-info', 'Preencha todos os campos!', 'alert-danger')
            } else if (!this.validarEmail(this.formData.email)) {
                this.mostrarMensagemAlerta('fa-solid fa-circle-exclamation', 'Digite um email válido!', 'alert-warning')
            } else if (this.formData.senha.length < 5) {
                this.mostrarMensagemAlerta('fa-solid fa-circle-exclamation', 'Digite uma senha mais forte!', 'alert-warning')
            } else if (this.formData.usuario.length < 5) {
                this.mostrarMensagemAlerta('fa-solid fa-circle-exclamation', 'Digite um nome de usuário válido!', 'alert-warning')
            } else {
                this.etapa++ // Avança para a próxima etapa se os campos estiverem preenchidos
            }
        } else if (this.etapa === 2) {
            // Verificação para a segunda etapa (verificação antes do envio)
            if (!this.formData.foto_perfil) {
                this.mostrarMensagemAlerta('fa-solid fa-circle-info', 'Escolha uma imagem válida (PNG, JPG, JPEG, GIF) com tamanho máximo de 10MB!', 'alert-danger')
            } else if (!this.validarImagem(this.formData.foto_perfil)) {
                this.mostrarMensagemAlerta('fa-solid fa-circle-info', 'Escolha uma imagem válida (PNG, JPG, JPEG, GIF) com tamanho máximo de 10MB!', 'alert-danger')
            } else {
                this.cadastrarUsuario() // Chama o método para cadastrar o usuário após a validação completa
            }
        }
    }

    async cadastrarUsuario() { //cadastrar usuario
        try {
            const formData = new FormData()
            formData.append('nome', this.formData.nome)
            formData.append('sobrenome', this.formData.sobrenome)
            formData.append('email', this.formData.email)
            formData.append('usuario', this.formData.usuario)
            formData.append('senha', this.formData.senha)
            formData.append('foto_perfil', this.formData.foto_perfil as Blob)

            /*const response = await axios.post('http://localhost/Projetos/linknest/src/backend/cadastrarUsuario.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })*/

            const response = await axios.post('https://linknest.com.br/src/backend/cadastrarUsuario.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })

            if (response.data.status === 'success') {
                this.mostrarMensagemAlerta('fa-solid fa-check', response.data.message, 'alert-success')
                this.etapa = 1
                this.limparFormulario()
            } else if (response.data.status === 'error') {
                this.mostrarMensagemAlerta('fa-solid fa-times', response.data.message, 'alert-danger')
            }
        } catch (error) {
            console.error('Erro ao cadastrar usuário:', error)
            this.mostrarMensagemAlerta('fa-solid fa-times', 'Erro ao cadastrar usuário', 'alert-danger')
        }
    }

    private mostrarMensagemAlerta(icone: string, mensagem: string, status: string) { //mostrar alerta
        this.mensagem_alerta = { icone, mensagem, status }
        setTimeout(() => {
            this.mensagem_alerta = null
        }, 5000) // Remover a mensagem após 5 segundos
    }

    private limparFormulario() { //limpar formulario
        this.formData.nome = ''
        this.formData.sobrenome = ''
        this.formData.email = ''
        this.formData.usuario = ''
        this.formData.senha = ''
        this.formData.foto_perfil = null
        this.mensagemSenha = ''
        this.mensagemUsuario = ''
        this.imagemPreview = ''
    }

    private validarEmail(email: string): boolean { //validar email
        // Expressão regular para validar email
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return regex.test(email)
    }

    public validarSenha() { //verificar senha

        const senha = this.formData.senha
        if (senha.length < 5) {
            this.mensagemSenha = 'Senha fraca'
            this.corMensagemSenha = 'red'
        } else if (senha.length >= 5 && senha.length < 8) {
            this.mensagemSenha = 'Vulnerável'
            this.corMensagemSenha = 'orange'
        } else if (senha.length >= 10 && !/[A-Z]/.test(senha)) {
            this.mensagemSenha = 'Forte'
            this.corMensagemSenha = 'green'
        } else if (senha.length >= 10 && /[A-Z]/.test(senha) && /[!@#$%^&*(),.?":{}|<>]/.test(senha)) {
            this.mensagemSenha = 'Senha muito forte'
            this.corMensagemSenha = 'greenyellow'
        } else if (senha.length >= 10) {
            this.mensagemSenha = 'Forte'
            this.corMensagemSenha = 'green'
        }

    }

    public validarUsuario() { //validar usuario

        const usuario = this.formData.usuario
        if (usuario.length < 5) {
            this.mensagemUsuario = 'Usuário muito curto'
            this.corMensagemUsuario = 'red'
        } else if (usuario.length >= 5) {
            this.mensagemUsuario = 'Usuário válido'
            this.corMensagemUsuario = 'greenyellow'
        }

    }
}
</script>

<style lang="scss">
@import '../scss/forms';
@import '../scss/alerts.scss';
</style>
