import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

//rotas
import AdicionarLinks from '../views/AdicionarLinks.vue'
import Ajuda from '../views/Ajuda.vue'
import AlterarSenha from '@/components/AlterarSenha.vue'
import Cadastro from '../views/Cadastro.vue'
import EditarPerfil from '../components/EditarPerfil.vue'
import EsqueceuSuaSenha from '../views/EsqueceuSuaSenha.vue'
import Index from '../views/Index.vue'
import LinkNestUsuario from '@/components/LinkNestUsuario.vue'
import Login from '../views/Login.vue'
import PaginaNaoEncontrada from '../components/PaginaNaoEncontrada.vue'
//import PesquisarUsuario from '@/views/PesquisarUsuario.vue'
import Perfil from '../components/Perfil.vue'
import RedefinirSenha from '../components/RedefinirSenha.vue'
import Sobre from '../views/Sobre.vue'

//store vuex
import store from '@/store'

const routes: Array<RouteRecordRaw> = [

  {
    path: '/', //Index.vue
    name: 'index',
    component: Index
  },

  {
    path: '/sobre', //Sobre.vue
    name: 'sobre',
    component: Sobre
  },

  {
    path: '/ajuda', //Ajuda.vue
    name: 'ajuda',
    component: Ajuda
  },

  {
    path: '/login', //Login.vue
    name: 'login',
    component: Login,
    meta: { requiresGuest: true } // metadado para indicar que precisa ser um visitante
  },

  {
    path: '/cadastro', //Cadastro.vue
    name: 'cadastro',
    component: Cadastro,
    meta: { requiresGuest: true } // metadado para indicar que precisa ser um visitante
  },

  {
    path: '/esqueceu-sua-senha', //EsqueceuSuaSenha.vue
    name: 'esqueceu-sua-senha',
    component: EsqueceuSuaSenha,
    meta: { requiresGuest: true } // metadado para indicar que precisa ser um visitante
  },

  {
    path: '/redefinir-senha', //RedefinirSenha.vue
    name: 'redefinir-senha',
    component: RedefinirSenha,
    props: route => ({ token: route.query.token }),
    meta: { requiresGuest: true } // metadado para indicar que precisa ser um visitante
  },

  {
    path: '/:usuario', // Rota dinâmica para exibir o perfil do usuário
    name: 'link-nest-usuario',
    component: LinkNestUsuario,
    props: true
  },

  /*{ //PesquisarUsuario.vue
    path: '/pesquisar-usuario',
    name: 'pesquisar-usuario',
    component: PesquisarUsuario
  },*/

  {
    path: '/:catchAll(.*)*', //PaginaNaoEncontrada.vue, se a pagina digitada nao existir
    component: PaginaNaoEncontrada
  },

  // Rotas protegidas que requerem autenticação
  {
    path: '/adicionar-links', //AdicionarLinks.vue
    name: 'adicionar-links',
    component: AdicionarLinks,
    meta: { requiresAuth: true } // Adicionando metadado para indicar que precisa de autenticação
  },

  {
    path: '/perfil', //Perfil.vue
    name: 'perfil',
    component: Perfil,
    meta: { requiresAuth: true } // Adicionando metadado para indicar que precisa de autenticação
  },

  {
    path: '/editar-perfil', //EditarPerfil.vue
    name: 'editar-perfil',
    component: EditarPerfil,
    meta: { requiresAuth: true } // Adicionando metadado para indicar que precisa de autenticação
  },

  {
    path: '/alterar-senha', //AlterarSenha.vue
    name: 'alterar-senha',
    component: AlterarSenha,
    meta: { requiresAuth: true } // Adicionando metadado para indicar que precisa de autenticação
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Guarda de navegação para verificar autenticação
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated // Obtém o estado de autenticação do Vuex

  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    // Redireciona para a página de login se a rota requer autenticação e o usuário não está autenticado
    next({ name: 'login' })
  } else if (to.matched.some(record => record.meta.requiresGuest) && isAuthenticated) {
    // Redireciona para a página de perfil se a rota requer ser um visitante e o usuário está autenticado
    next({ name: 'perfil' })
  } else {
    // Permite o acesso à rota
    next()
  }
})

export default router