<template>
    <div class="container">
        <div class="mt-5 mb-5 como-podemos-ajudar">
            <div class="row">
                <div class="col">
                    <h2>Como podemos te ajudar? </h2>
                    <div class="input-group mb-5 mt-5">
                        <input v-model="pesquisa" type="text" class="form-control" placeholder="O que você precisa?"
                            aria-label="Recipient's username" aria-describedby="basic-addon2">
                        <div class="input-group-append">
                            <button class="d-none d-lg-inline btn btn-linknest btn-input-group"
                                style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;" type="button"
                                @click="realizarPesquisa"><i class="fa-solid fa-magnifying-glass"></i>
                                Pesquisar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="encontrouResultados">
        <div class="criar-conta" v-show="mostrarSecao('criar-conta')">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <h2 class="mb-5">Como criar uma conta no LinkNest?</h2>
                        <p>Para criar um LinkNest, clique no botão 'Cadastrar' na página inicial, preencha seus dados e
                            siga as instruções para configurar seu primeiro LinkConnect, você também pode criar sua
                            conta
                            através de sua conta Google.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="redefinir-senha" v-show="mostrarSecao('redefinir-senha')">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <h2 class="mb-5">Como redefinir minha senha?</h2>
                        <p>Clique no link 'Esqueceu sua senha?' na página de login, insira seu e-mail e siga as
                            instruções
                            enviadas para redefinir sua senha.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="adicionar-links" v-show="mostrarSecao('adicionar-links')">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <h2 class="mb-5">Como adicionar links ao meu LinkNest?</h2>
                        <p>Depois de fazer login, vá para o painel de controle, clique em 'Adicionar Link', insira a URL
                            e o
                            título do link, e clique em 'Salvar'</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="aparencia" v-show="mostrarSecao('aparencia')">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <h2 class="mb-5">Como personalizar a aparência do meu LinkNest?</h2>
                        <p>No painel de controle, vá para a seção 'Personalização', onde você pode escolher temas, cores
                            e
                            fontes para o seu LinkNest.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="duvida-nao-encontrada" v-show="mostrarSecao('duvida-nao-encontrada')">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <h2 class="mb-5">Não encontrou sua dúvida? Entre em contato conosco agora mesmo!</h2>
                        <h6 class="mb-5"><i class="fa-solid fa-envelope"></i> E-mail: linknest2024@gmail.com</h6>
                        <h5 class="mb-5">O tempo de prazo para resposta vai de 24 a 48 horas, talvez demore mais a
                            depender
                            da demanda,
                            mas tentaremos te responder o mais rápido possível</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="container">
        <div class="row">
            <div class="alert alert-secondary text-center" role="alert">
                Não encontramos resultados para "{{ pesquisa }}"
            </div>
        </div>
    </div>
</template>



<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class Ajuda extends Vue {

    public pesquisa = ''

    public secoes: { [key in 'criar-conta' | 'redefinir-senha' | 'adicionar-links' | 'aparencia' | 'duvida-nao-encontrada']: string } = {
        'criar-conta': 'Como criar uma conta no LinkNest? Para criar um LinkNest, clique no botão "Cadastrar" na página inicial, preencha seus dados e siga as instruções para configurar seu primeiro LinkConnect, você também pode criar sua conta através de sua conta Google.',
        'redefinir-senha': 'Como redefinir minha senha? Clique no link "Esqueceu sua senha?" na página de login, insira seu e-mail e siga as instruções enviadas para redefinir sua senha.',
        'adicionar-links': 'Como adicionar links ao meu LinkNest? Depois de fazer login, vá para o painel de controle, clique em "Adicionar Link", insira a URL e o título do link, e clique em "Salvar".',
        'aparencia': 'Como personalizar a aparência do meu LinkNest? No painel de controle, vá para a seção "Personalização", onde você pode escolher temas, cores e fontes para o seu LinkNest.',
        'duvida-nao-encontrada': 'Não encontrou sua dúvida? Entre em contato conosco agora mesmo! O tempo de prazo para resposta vai de 24 a 48 horas, talvez demore mais a depender da demanda, mas tentaremos te responder o mais rápido possível. E-mail: linknest@gmail.com'
    };

    public realizarPesquisa() { //realizar pesquisa
        this.pesquisa = this.pesquisa.trim().toLowerCase()
    }

    public mostrarSecao(secao: 'criar-conta' | 'redefinir-senha' | 'adicionar-links' | 'aparencia' | 'duvida-nao-encontrada'): boolean {
        if (!this.pesquisa) return true

        const conteudoSecao = this.secoes[secao].toLowerCase()
        return conteudoSecao.includes(this.pesquisa)
    }

    public get encontrouResultados(): boolean { //encontrou resultados
        for (let secao in this.secoes) {
            if (this.mostrarSecao(secao as 'criar-conta' | 'redefinir-senha' | 'adicionar-links' | 'aparencia' | 'duvida-nao-encontrada')) {
                return true
            }
        }
        return false
    }
}
</script>


<style lang="scss">
@import '@/scss/ajuda.scss';
</style>