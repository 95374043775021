<template>
    <div class="forms">
        <div class="container mt-5 mb-5">
            <div class="alert alert-warning">
                <h2 class="text-center mb-4 alert-heading"><i class="fa-solid fa-triangle-exclamation"></i> Página em
                    desenvolvimento!</h2>
                <p class="text-center">Esta página ainda encontra-se em desenvolvimento, portanto, pode não funcionar corretamente,
                    mas logo estará funcionando corretamente. Obrigado pela compreensão :)</p>
            </div>
            <div class="card">
                <div class="card-header">
                    <h3 class="bem-vindo-de-volta">Redefinição de senha</h3>
                    <h5>Digite seu e-mail para enviarmos um e-mail para redefinição de sua senha</h5>
                    <div v-if="mensagemAlerta" class="mt-3 mb-3 text-center alert" :class="mensagemAlerta.status">
                        <i :class="mensagemAlerta.icone"></i> {{ mensagemAlerta.mensagem }}
                    </div>
                </div>
                <div class="card-body">
                    <form @submit.prevent="enviarEmailRedefinicao">
                        <div class="mb-3">
                            <label for="email" class="form-label">E-mail</label>
                            <input v-model="email" placeholder="Digite seu e-mail" type="email" class="form-control">
                        </div>
                        <div class="mt-5 d-grid gap-2 col-6 mx-auto mb-5">
                            <button type="submit" class="btn btn-linknest">Enviar e-mail</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import axios from 'axios';
import { Alerta } from '@/interfaces/Alerta';

@Options({})
export default class EsqueceuSuaSenha extends Vue {
    email = ''
    mensagemAlerta: Alerta | null = null

    private validarEmail(email: string): boolean {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return regex.test(email)
    }

    private mostrarMensagemAlerta(icone: string, mensagem: string, status: string) {
        this.mensagemAlerta = { icone, mensagem, status }
        setTimeout(() => {
            this.mensagemAlerta = null;
        }, status === 'alert-primary' ? 3000 : 12000)
    }

    public async enviarEmailRedefinicao() {
        console.log('Chamando enviarEmailRedefinicao')
        if (!this.validarEmail(this.email)) {
            this.mostrarMensagemAlerta('fa-solid fa-circle-exclamation', 'Digite um e-mail válido!', 'alert-warning')
            return
        }

        try {
            this.mostrarMensagemAlerta('fa-solid fa-clock', 'Enviando e-mail para redefinição de senha', 'alert-primary')
            const startTime = performance.now()
            const response = await axios.post('https://linknest.com.br/src/backend/redefinirSenha.php', { email: this.email }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const endTime = performance.now()
            console.log(`Tempo de resposta: ${endTime - startTime} ms`)
            console.log(response.data)
            if (response.data.status === 'success') {
                this.mostrarMensagemAlerta('fa-solid fa-check', response.data.message, 'alert-success')
                this.email = ''
            } else if (response.data.status === 'error') {
                this.mostrarMensagemAlerta('fa-solid fa-times', response.data.message, 'alert-danger')
            }
        } catch (error) {
            console.error('Erro ao enviar e-mail de redefinição:', error)
            this.mostrarMensagemAlerta('fa-solid fa-times', 'Erro ao enviar e-mail de redefinição', 'alert-danger')
        }
    }
}
</script>

<style lang="scss">
@import '../scss/forms';
@import '../scss/alerts.scss';
</style>
