<template>
    <footer>
        <div class="rodape">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <router-link class="link-footer" to="/ajuda">
                            <h5>Ajuda / Suporte</h5>
                        </router-link>
                        <router-link class="link-footer" to="sobre">
                            <h5>Sobre nós</h5>
                        </router-link>
                    </div>
                    <div class="col-md-6">
                        <h5>Contato por e-mail: linknest2024@gmail.com</h5>
                    </div>
                    <div class="col-md-12">
                        <h5>Todos os direitos reservados</h5>
                        <h5>LinkNest - 2024</h5>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({

})
export default class Footer extends Vue {

}
</script>

<style lang="scss">
@import '../scss/footer';
</style>