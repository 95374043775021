<template>
    <div class="forms">
        <div class="container mt-5 mb-5">
            <div v-if="isLoading" class="loading">
                <div class="container">
                    <div class="row">
                        <div class="d-flex justify-content-center">
                            <div class="spinner"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else>
                <div class="card">
                    <div class="card-header">
                        <h5>Seu perfil</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="mb-3">
                                <div class="d-flex align-self-center justify-content-center">
                                    <div class="painel-usuario mb-5 mt-3">
                                        <img :src="user?.foto_perfil ? `data:image/jpeg;base64,${user.foto_perfil}` : require('@/assets/imgs/profile.png')"
                                            class="img-fluid">
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3 col-md-6">
                                <label for="nome" class="form-label">Nome</label>
                                <input disabled :value="user?.nome" placeholder="Nome" type="text" class="form-control">
                            </div>
                            <div class="mb-3 col-md-6">
                                <label for="sobrenome" class="form-label">Sobrenome</label>
                                <input disabled :value="user?.sobrenome" placeholder="Sobrenome" type="text"
                                    class="form-control">
                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="email" class="form-label">Email</label>
                            <input disabled :value="user?.email" placeholder="Email" type="email" class="form-control">
                        </div>
                        <div class="mb-3">
                            <label for="usuario" class="form-label">Usuário</label>
                            <input disabled :value="user?.usuario" placeholder="Usuário" type="text"
                                class="form-control">
                        </div>
                        <div class="mt-5 d-grid gap-2 col-6 mx-auto mb-5">
                            <router-link :to="{ name: 'editar-perfil' }" class="btn btn-linknest">
                                <i class="fa-solid fa-user-pen"></i> Editar perfil
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="card mt-3">
                    <div class="card-header">
                        <h5>Segurança</h5>
                    </div>
                    <div class="card-body">
                        <div class="mt-5 d-grid gap-2 col-6 mx-auto mb-5">
                            <router-link :to="{ name: 'alterar-senha' }" class="btn btn-linknest">
                                <i class="fa-solid fa-lock"></i> Alterar senha
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import axios from 'axios'
import { User } from '@/interfaces/User'

@Options({})
export default class Perfil extends Vue {
    user: User | null = null
    isLoading = true

    created() {
        this.fetchUserData()
    }

    async fetchUserData() {
        const userId = localStorage.getItem('userId')
        if (userId) {
            try {
                //const response = await axios.get(`http://localhost/Projetos/linknest/src/backend/getPerfil.php?userId=${userId}`)
                const response = await axios.get(`https://linknest.com.br/src/backend/getPerfil.php?userId=${userId}`)
                if (response.data.status === 'success') {
                    this.user = response.data.user
                } else {
                    console.error('Erro ao buscar dados do usuário:', response.data.message)
                }
            } catch (error) {
                console.error('Erro ao buscar dados do usuário:', error)
            }
        } else {
            console.error('Usuário não autenticado')
        }

        this.isLoading = false
    }
}
</script>

<style lang="scss">
@import '../scss/forms';
</style>
