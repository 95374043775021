<template>
    <div class="container">
        <div class="secao-hero mt-5">
            <div class="row">
                <div class="col-md-4 text-center d-flex align-items-center justify-content-center">
                    <h1>Todos os seus links em um só lugar</h1>
                </div>
                <div class="col-md-4 text-center d-flex align-items-center justify-content-center">
                    <h3>Com o LinkNest, você pode divulgar todos os seus links em apenas um</h3>
                </div>
                <div class="col-md-4">
                    <img class="img-fluid" src="../assets/imgs/imagem.png">
                </div>
                <div class="col-md-12">
                    <div class="d-grid gap-2 col-6 mx-auto mb-5">
                        <router-link to="/cadastro" class="btn btn-linknest" type="button">Começar agora</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="recursos-principais mt-5">
        <div class="container">
            <div class="row">
                <div class="col text-center mt-3">
                    <h1>Recursos principais</h1>
                    <h2>Personalização de links</h2>
                    <h2>Iteração com redes sociais</h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

export default class Index extends Vue { }
</script>

<style lang="scss">
@import '../scss/index.scss';
</style>