<template>
    <div class="missao-e-visao">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h1>Missão e visão do LinkNest</h1>

                    <div class="missao">
                        <h5>Missão: Nossa missão no LinkNest é fornecer uma plataforma simples e eficaz para ajudar
                            pessoas e empresas a centralizar seus links em um único lugar, facilitando a conexão e a
                            descoberta de conteúdo.</h5>
                    </div>

                    <div class="visao">
                        <h5>Visão: Ser a principal ferramenta global para gestão de links, conectando milhões de
                            usuários a
                            um mundo de conteúdo com apenas um clique.</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="historia">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h1>História do Link Nest</h1>
                    <h5>O LinkNest foi criado em 2024 com o objetivo de simplificar a forma como as pessoas
                        compartilham seus links. Nascido da necessidade de organizar e centralizar links de maneira
                        eficiente, uma plataforma totalmente segura a disposição de ajudar a todos que quiserem
                        compartilhar seus links de uma forma totalmente organizada.</h5>
                </div>
            </div>
        </div>
    </div>

    <div class="forms">
        <h1>Entre em contato conosco agora mesmo!</h1>
        <div class="container mt-5 mb-5">
            <div class="card">
                <div class="card-header">

                    <h3>Te mandaremos um e-mail
                        respondendo sua dúvida o mais rápido possível, pode demorar até 48 horas, à depender da demanda.</h3>
                    <div class="mt-5" style="background-color: rgb(189, 0, 0);
                        border-radius: 20px;
                        padding: 15px;
                        box-shadow: black 4px 4px 4px;
                        text-shadow: black 4px 4px 4px;">
                        <h6><i class="fa-solid fa-envelope"></i> E-mail: linknest2024@gmail.com</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import axios from 'axios'
import { Alerta } from '@/interfaces/Alerta'

@Options({})
export default class Sobre extends Vue {
}
</script>

<style lang="scss">
@import '../scss/sobre.scss';
</style>