<template>
    <nav class="navbar navbar-expand-lg navbar-linknest">
        <div class="container-fluid">
            <router-link class="navbar-brand" to="/"><img class="img-fluid mb-1" src="../assets/logo.png">Link
                Nest</router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse justify-content-center flex-wrap" id="navbarNav">
                <ul class="navbar-nav flex-wrap">
                    <li class="nav-item">
                        <router-link to="/" :class="{ active: $route.path === '/' }" class="nav-link">
                            <i class="fa-solid fa-house"></i> Home
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/sobre" :class="{ active: $route.path === '/sobre' }" class="nav-link">
                            <i class="fa-solid fa-circle-exclamation"></i> Sobre
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/ajuda" :class="{ active: $route.path === '/ajuda' }" class="nav-link">
                            <i class="fa-solid fa-question"></i> Ajuda
                        </router-link>
                    </li>
                    <!--<li class="nav-item">
                        <router-link to="/pesquisar-usuario" :class="{ active: $route.path === '/pesquisar-usuario' }"
                            class="nav-link">
                            <i class="fa-solid fa-magnifying-glass"></i> Pesquisar
                        </router-link>
                    </li>-->

                    <li v-if="isAuthenticated" class="nav-item">
                        <router-link to="/adicionar-links" :class="{ active: $route.path === '/adicionar-links' }"
                            class="nav-link">
                            <i class="fa-solid fa-plus"></i> Adicionar links
                        </router-link>
                    </li>
                    <li v-if="isAuthenticated" class="nav-item">
                        <router-link to="/perfil" :class="{ active: $route.path === '/perfil' }" class="nav-link">
                            <i class="fa-solid fa-user"></i> Meu perfil
                        </router-link>
                    </li>
                </ul>
            </div>

            <div class="collapse navbar-collapse justify-content-end flex-wrap" id="navbarNav">
                <ul class="navbar-nav flex-wrap">
                    <li class="nav-item" v-if="isAuthenticated">
                        <h5 class="navbar-text me-2"><i class="fa-solid fa-user"></i> {{ user?.usuario }}</h5>
                        <button @click="logout" class="btn btn-linknest"><i class="fa-solid fa-right-from-bracket"></i>
                            Logout</button>
                    </li>
                    <li class="nav-item" v-else>
                        <router-link to="/login" :class="{ active: $route.path === '/login' }">
                            <button class="btn btn-linknest me-2 mb-2 mt-2">Login</button>
                        </router-link>
                    </li>
                    <li class="nav-item" v-if="!isAuthenticated">
                        <router-link to="/cadastro" :class="{ active: $route.path === '/cadastro' }">
                            <button class="btn btn-linknest mt-2">Cadastre-se</button>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
})

export default class Navbar extends Vue {

    public logout() { //logout
        // Limpar dados no Vuex
        this.$store.dispatch('logout')

        //voltar para a rota de login
        this.$router.push('/login')
    }

    get isAuthenticated() { //usuario autenticado
        return this.$store.getters.isAuthenticated
    }

    get user() { //usuario
        return this.$store.getters.user
    }
}
</script>

<style lang="scss">
@import '../scss/navbar.scss';

.navbar-nav.flex-wrap .nav-item {
    flex: 1 1 auto;
    /* Allows items to grow and shrink as needed */
}

.nav-link {
    white-space: nowrap;
    /* Prevent text from wrapping within individual items */
}
</style>