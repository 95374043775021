<template>
    <div v-if="userNotFound" class="container">
        <div class="row">
            <div class="alert alert-danger mt-3" role="alert">
                <h4 class="alert-heading">Opss!</h4>
                <p>Este usuário não existe :/</p>
                <hr>
                <button type="button" class="btn btn-danger" @click="voltarPaginaAnterior()">Voltar para a página
                    anterior</button>
            </div>
        </div>
    </div>
    <div v-else class="forms">
        <div class="container mt-5 mb5">

            <div v-if="isLoading" class="text-center">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Carregando...</span>
                </div>
            </div>

            <div v-else>
                <div class="painel-usuario">
                    <div class="d-flex justify-content-center mb-3">
                        <img class="img-fluid"
                            :src="user.foto_perfil ? `data:image/jpeg;base64,${user.foto_perfil}` : require('@/assets/imgs/profile.png')">
                    </div>
                    <h4>{{ user.nome }} {{ user.sobrenome }}</h4>
                    <h5>@{{ user.usuario }}</h5>
                </div>

                <div class="card mt-5 mb-5">
                    <div class="card-body">
                        <div class="d-grid gap-3">
                            <div v-if="links.length === 0" class="alert alert-secondary text-center">
                                Este usuário ainda não adicionou links.
                            </div>
                            <button v-else @click="navigateToLink(link.url_link)" v-for="link in links" :key="link.id"
                                :class="['btn', getButtonClass(link.nome_link)]" type="button"><i
                                    :class="getIconClass(link.icone_link)"></i> {{ link.nome_link }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import axios from 'axios'
import { User } from '@/interfaces/User'
import { Link } from '@/interfaces/Link'

@Options({
    props: {
        usuario: String
    }
})
export default class LinkNestUsuario extends Vue {
    user: User = {
        id: '',
        nome: '',
        sobrenome: '',
        email: '',
        usuario: '',
        foto_perfil: null
    }
    links: Link[] = []
    isLoading = true
    userNotFound = false //se usuario nao existir

    mounted() { // Verifica se o usuário está autenticado ao montar o componente
        this.fetchUserData()
    }

    async fetchUserData() {
        this.isLoading = true
        const usuario = this.$route.params.usuario

        try {
            /*const userResponse = await axios.get('http://localhost/Projetos/linknest/src/backend/getUsuario.php', {
                params: { usuario: usuario }
            })*/
            const userResponse = await axios.get('https://linknest.com.br/src/backend/getUsuario.php', {
                params: { usuario: usuario }
            })

            if (userResponse.data.status === 'success') {
                this.user = userResponse.data.user

                /*const linksResponse = await axios.get('http://localhost/Projetos/linknest/src/backend/buscarLinks.php', {
                    params: { user_id: this.user.id }
                })*/

                const linksResponse = await axios.get('https://linknest.com.br/src/backend/buscarLinks.php', {
                    params: { user_id: this.user.id }
                })

                this.links = linksResponse.data.map((link: Link) => {
                    switch (link.nome_link.toLowerCase()) {
                        case 'instagram':
                            link.icone_link = 'instagram'
                            break
                        case 'gmail':
                            link.icone_link = 'gmail'
                            break
                        case 'linkedin':
                            link.icone_link = 'linkedin'
                            break
                        case 'tiktok':
                            link.icone_link = 'tiktok'
                            break
                        case 'facebook':
                            link.icone_link = 'facebook'
                            break
                        case 'twitter':
                            link.icone_link = 'x'
                            break
                        case 'whatsapp':
                            link.icone_link = 'whatsapp'
                            break
                        case 'github':
                            link.icone_link = 'github'
                            break
                        case 'youtube':
                            link.icone_link = 'youtube'
                            break
                        case 'portfolio':
                        case 'portfólio':
                            link.icone_link = 'portfolio'
                            break
                        case 'curriculo':
                        case 'currículo':
                            link.icone_link = 'curriculo'
                            break
                        default:
                            link.icone_link = 'link'
                    }
                    return link
                })
            } else {
                this.userNotFound = true // Define userNotFound como true se o usuário não for encontrado
            }
        } catch (error) {
            console.error('Erro ao buscar dados do usuário:', error)
        } finally {
            this.isLoading = false
        }
    }

    public navigateToLink(url: string) { //redirecionar para o link que o usuario inseriu naquele botao
        window.open(url, '_blank')
    }

    getButtonClass(nome_link: string) { //classe de botoes
        switch (nome_link.toLowerCase()) {
            case 'instagram':
                return 'btn-warning'
            case 'linkedin':
                return 'btn-primary'

            case 'curriculo':
            case 'currículo':
                return 'btn-light'
            case 'portfolio':
            case 'portfólio':
                return 'btn-success'
            case 'github':
            case 'twitter':
            case 'x':
            case 'tiktok':
                return 'btn-dark'
            case 'whatsapp':
                return 'btn-success'
            case 'facebook':
                return 'btn-info'
            case 'youtube':
            case 'gmail':
                return 'btn-danger'
            default:
                return 'btn-secondary'
        }
    }

    getIconClass(icone_link?: string) { //classe de icones
        if (!icone_link) {
            return 'fa-solid fa-link' // ícone padrão
        }
        switch (icone_link.toLowerCase()) {
            case 'instagram':
                return 'fa-brands fa-instagram'
            case 'gmail':
                return 'fa-solid fa-envelope'
            case 'linkedin':
                return 'fa-brands fa-linkedin'
            case 'tiktok':
                return 'fa-brands fa-tiktok'
            case 'facebook':
                return 'fa-brands fa-facebook'
            case 'github':
                return 'fa-brands fa-github'
            case 'x':
            case 'twitter':
                return 'fa-brands fa-x-twitter'
            case 'whatsapp':
                return 'fa-brands fa-whatsapp'
            case 'youtube':
                return 'fa-brands fa-youtube'
            case 'portfolio':
            case 'portfólio':
                return 'fa-solid fa-briefcase'
            case 'curriculo':
            case 'currículo':
                return 'fa-solid fa-id-card'
            default:
                return 'fa-solid fa-link'
        }
    }

    voltarPaginaAnterior() { //voltar para a pagina que estava anteriormente
        this.$router.go(-1)
    }
}

</script>

<style lang="scss">
@import '../scss/forms.scss';

.spinner-border {
    width: 3rem;
    height: 3rem;
}

.text-center {
    text-align: center;
    margin-top: 2rem;
}
</style>
