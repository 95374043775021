import { createStore } from 'vuex';

interface State {
  user: { usuario: string } | null
  isAuthenticated: boolean
}

const store = createStore<State>({
  state: {
    // Inicializa o estado com os dados do localStorage, se existirem
    user: JSON.parse(localStorage.getItem('user') || 'null'),
    // Verifica se há um usuário autenticado com base nos dados do localStorage
    isAuthenticated: !!localStorage.getItem('user'),
  },

  getters: {
    // Getter para verificar se o usuário está autenticado
    isAuthenticated: (state) => state.isAuthenticated,
    // Getter para obter os dados do usuário
    user: (state) => state.user,
  },

  mutations: {
    // Mutação para definir os dados do usuário e atualizar o estado de autenticação
    setUser(state, user) {
      state.user = user
      state.isAuthenticated = !!user
      // Armazena os dados do usuário no localStorage
      localStorage.setItem('user', JSON.stringify(user))
    },

    // Mutação para limpar os dados do usuário e definir isAuthenticated como falso
    logout(state) {
      state.user = null
      state.isAuthenticated = false
      
      // Remove os dados do usuário do localStorage ao fazer logout
      localStorage.removeItem('user')
    },
  },

  actions: {
    // Ação para realizar o login, chamando a mutação setUser
    login({ commit }, user) {
      commit('setUser', user)
    },

    // Ação para realizar o logout, chamando a mutação logout
    logout({ commit }) {
      commit('logout')
    },
  },
});

export default store;
