<template>
    <div class="forms">
        <div class="container mt-5 mb-5">
            <div class="card">
                <div class="card-header">
                    <h3 v-if="etapa === 1" class="bem-vindo-de-volta">Redefinição de senha</h3>
                    <h5 v-if="etapa === 1">Digite sua nova senha abaixo:</h5>
                </div>
                <div class="card-body">
                    <!-- Etapa 1: Formulário de redefinição de senha -->
                    <template v-if="etapa === 1">
                        <form @submit.prevent="validarFormulario">
                            <div v-if="mensagemAlerta" class="mt-3 mb-3 text-center alert"
                                :class="mensagemAlerta.status">
                                <i :class="mensagemAlerta.icone"></i> {{ mensagemAlerta.mensagem }}
                            </div>
                            <div class="mb-3">
                                <label for="senha" class="form-label">Senha</label>
                                <div class="input-group mb-3">
                                    <input v-model="novaSenha" :type="mostrar_senha ? 'text' : 'password'"
                                        @input="validarSenha" class="form-control" placeholder="Digite sua nova senha"
                                        name="senha" aria-label="Senha" aria-describedby="button-addon1">
                                    <button @click="alternarExibicaoSenha()" class="btn btn-warning" type="button"
                                        id="button-addon1">
                                        <i class="fa-solid"
                                            :class="{ 'fa-eye-slash': mostrar_senha, 'fa-eye': !mostrar_senha }"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="mb-3">
                                <span :style="{ color: corMensagemSenha }">{{ mensagemSenha }}</span>
                            </div>
                            <div class="mb-3">
                                <label for="confirmarSenha" class="form-label">Confirmar Senha</label>
                                <div class="input-group mb-3">
                                    <input v-model="confirmarSenha"
                                        :type="mostrar_confirmar_senha ? 'text' : 'password'" class="form-control"
                                        placeholder="Confirme sua nova senha" name="confirmarSenha"
                                        aria-label="Confirmar Senha" aria-describedby="button-addon2">
                                    <button @click="alternarExibicaoConfirmarSenha()" class="btn btn-warning"
                                        type="button" id="button-addon2">
                                        <i class="fa-solid"
                                            :class="{ 'fa-eye-slash': mostrar_confirmar_senha, 'fa-eye': !mostrar_confirmar_senha }"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="mt-5 d-grid gap-2 col-6 mx-auto mb-5">
                                <button type="submit" class="btn btn-linknest">Redefinir Senha</button>
                            </div>
                        </form>
                    </template>

                    <!-- Etapa 2: Mensagem de sucesso -->
                    <template v-else-if="etapa === 2">
                        <div class="text-center d-flex justify-content-center">
                            <h2 class="texto-successo mb-5">Sucesso!</h2>
                        </div>
                        <h5 class="text-center">Sua senha foi redefinida com sucesso!</h5>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import axios from 'axios'
import { Alerta } from '@/interfaces/Alerta'

@Options({})
export default class RedefinirSenha extends Vue {

    novaSenha = ''
    confirmarSenha = ''
    mostrar_senha = false
    mostrar_confirmar_senha = false
    mensagemSenha = ''
    corMensagemSenha = 'black'
    mensagemAlerta: Alerta | null = null
    etapa = 1 // Etapa inicial

    alternarExibicaoSenha(): void { //alternar exibicao senha
        this.mostrar_senha = !this.mostrar_senha
    }

    alternarExibicaoConfirmarSenha(): void { //alternar exibicao confirmar senha
        this.mostrar_confirmar_senha = !this.mostrar_confirmar_senha
    }

    public validarFormulario() { //validar formulario
        if (this.novaSenha.length < 5) {
            this.mostrarMensagemAlerta('fa-solid fa-circle-exclamation', 'Digite uma senha mais forte!', 'alert-warning')
        } else {
            this.enviarFormulario()
        }
    }

    async enviarFormulario(): Promise<void> { //enviar formulario

        if (this.validarSenhas()) {
            try {
                // Simulando uma chamada de API com Axios
                /*const response = await axios.post('http://localhost/Projetos/linknest/src/backend/redefinirSenhaToken.php', {
                    token: this.$route.query.token,
                    novaSenha: this.novaSenha,
                })*/

                const response = await axios.post('https://linknest.com.br/src/backend/redefinirSenhaToken.php', {
                    token: this.$route.query.token,
                    novaSenha: this.novaSenha,
                })

                if (response.data.status === 'success') {
                    this.etapa = 2 // Mudar para a etapa de sucesso

                    // Após 10 segundos, redirecionar para a página de login
                    setTimeout(() => {
                        this.$router.push('/login')
                    }, 5000)
                } else {
                    this.mostrarMensagemAlerta('fa-solid fa-times', response.data.message, 'alert-danger')
                }
            } catch (error) {
                console.error('Erro ao redefinir senha:', error)
                this.mostrarMensagemAlerta('fa-solid fa-times', 'Erro ao redefinir senha', 'alert-danger')
            }
        } else {
            this.mostrarMensagemAlerta('fa-solid fa-circle-exclamation', 'As senhas não coincidem!', 'alert-warning')
        }
    }

    private validarSenhas(): boolean { //verificar se as duas senhas estao iguais
        return this.novaSenha === this.confirmarSenha
    }

    public validarSenha() { //validar nivel da senha
        const senha = this.novaSenha
        if (senha.length < 5) {
            this.mensagemSenha = 'Senha fraca'
            this.corMensagemSenha = 'red'
        } else if (senha.length >= 5 && senha.length < 8) {
            this.mensagemSenha = 'Vulnerável'
            this.corMensagemSenha = 'orange'
        } else if (senha.length >= 10 && !/[A-Z]/.test(senha)) {
            this.mensagemSenha = 'Forte'
            this.corMensagemSenha = 'green'
        } else if (senha.length >= 10 && /[A-Z]/.test(senha) && /[!@#$%^&*(),.?":{}|<>]/.test(senha)) {
            this.mensagemSenha = 'Senha muito forte'
            this.corMensagemSenha = 'greenyellow'
        } else if (senha.length >= 10) {
            this.mensagemSenha = 'Forte'
            this.corMensagemSenha = 'green'
        }
    }

    private mostrarMensagemAlerta(icone: string, mensagem: string, status: string): void {
        this.mensagemAlerta = { icone, mensagem, status }
        setTimeout(() => {
            this.mensagemAlerta = null
        }, 12000)
    }
}
</script>

<style lang="scss">
@import '../scss/forms';
</style>
